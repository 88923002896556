.countryFlag{
    display: flex;
    flex-direction: row;
    align-items: center;

    img{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 8px;
        border: 1px solid #c1c5c8;
    }
}