.iconContainer{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //max-width: 350px;

  @media (max-width: 799px){
    padding-left: 10px;
  }

  span{
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    background-color:#405363;
    &:last-child{
      margin-right: 0;
    }
    svg{
      width: 6px;
      height: auto;
    }
  }

}

//.iconCount1{
//  span{
//    background-color: #9CA3AA;
//  }
//}
//
//.iconCount2{
//  span{
//    background-color: #7D8992;
//  }
//}
//
//.iconCount3{
//  span{
//    background-color: #5F6E7B;
//  }
//}
//
//.iconCount4{
//  span{
//    background-color: #405363;
//  }
//}



