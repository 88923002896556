.cellHideMobile{
  @media (max-width: 799px){
      display: none;

      // &.selectedColumn{
      //     display: table-cell;
      // }
  }
}

.cellOnMobile{
  @media (min-width: 800px){
      // display: none;

      &.selectedColumn{
          display: table-cell;
      }
  }
}

.displayColumnTitleDiv {
  // @media (max-width: 799px) {
  //   &.selectedColumn {
    display: table-cell;
  //   }
  // }
}

.sortColumnTitle {
  display: inline-flex;
  align-items: flex-end;
  flex-direction: row;
  vertical-align: bottom;

  svg {
    flex: 0 0 14px;
    width: 14px;
    height: auto;
    margin-left: 10px;
    //margin-right: 5px;
    display: inline-block;
    vertical-align: bottom;
  }

  span{
    display: inline-block;
    vertical-align: text-bottom;
    margin-bottom: -3px;
  }

  &.reverseSortColumnTitle{
    justify-content: space-between;
    @media (min-width:800px) {
      flex-direction: row-reverse;
      svg{
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
}

th.sortableColumn{
  cursor: pointer;
  &.selectedColumn{
      font-weight: 600;
      .sortColumnTitle{
          svg{
            g{
              fill:var(--color-app-henley-blue);
            }
          }
      }
  }

}

.selectedColumn{
  background: #f7f7f7;
}

.sortMobileWrapper{
  margin-bottom: 20px;
}

.sortTitle{
  font-size: 12px;
  font-family: "Roboto Regular";
  margin-bottom: 15px;
}


.cityColumn{
  .columnText{
    margin-bottom: -3px;
    display: inline-flex;
    vertical-align: bottom;
  }
  @media (max-width:799px){
      width: 40%;
  }
}


.hiddenTableRow{
  @media (min-width:800px){
      display: none;
  }

}



.overviewChartTable{
  max-width: 990px;
  .contentRow{
      @media (max-width: 799px){
          cursor: pointer;

          &.openedRow{
            td{
              &:first-child{
                border-bottom: 0;
              }
            }
          }
      }
  }
}

.linkText{
  color: inherit;
  text-decoration: underline;
  font-size: 16px;
}

.combinateRowCells {
  border-top: 1px solid #C1C5C8;
  border-left: 1px solid #C1C5C8;
  border-right: 0!important;
  vertical-align: middle;
  padding: 30px 10px 30px 0!important;
}

.countryMobileDiv {
  @media (max-width: 799px) {
    padding-top: 0!important;
  }
}

.showTextOnMobile{
  @media (min-width: 800px) {
    display: none;
  }
}