.tableTextWrapper{
  font-family: "Roboto Regular";
  color: #000;
  font-size: 10px;
  h5{
    font-family: inherit;
    font-size: inherit;
    line-height: 13px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: initial;
    letter-spacing: normal;
    color: #4B4B4D;
  }
}

.legendList{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 799px){
    flex-direction: column;
    align-items: flex-start;
  }
  .each{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @media (max-width: 799px) {
    margin-left: -10px;
  }
}

.iconWrapper{
  @media (max-width: 799px){
    width: 142px;
  }

}

.legendText{
  margin-left: 10px;
  font-family: "Roboto Medium";
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  .legendNumber{
    font-weight: 400;
    font-family: "Roboto Regular";
  }
}

.footerNotes{
  //font-size: 12px;
  //line-height: 1.2;
  //color:  var(--color-app-dark-gray);
  font-family: "Roboto Regular";
  margin-bottom: 50px;

  ul,li,p{
    font-size: inherit;
  }

  ul{
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 10px;
    line-height: 1.4;
    li{
      margin-bottom: 2px;
      padding: 0;

      &:before{
        display: none;
      }
    }
  }
}