.cellHideMobile {
  @media (max-width: 799px) {
    display: none;

    // &.selectedColumn {
    //   display: table-cell;
    // }
  }
}

.cellOnMobile {
  @media (min-width: 800px) {
    // display: none;

    &.selectedColumn {
      display: table-cell;
    }
  }
}

.displayColumnTitleDiv {
  // @media (max-width: 799px) {
  //   &.selectedColumn {
    display: table-cell;
  //   }
  // }
}

.sortColumnTitle {
  display: inline-flex;
  align-items: flex-end;
  flex-direction: row;
  vertical-align: bottom;

  svg {
    flex: 0 0 14px;
    width: 14px;
    height: auto;
    margin-left: 10px;
    //margin-right: 5px;
    display: inline-block;
    vertical-align: bottom;
  }

  span{
    display: inline-block;
    vertical-align: text-bottom;
    margin-bottom: -3px;
  }

  &.reverseSortColumnTitle{
    justify-content: space-between;
    @media (min-width:800px) {
      flex-direction: row-reverse;
      svg{
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
}

th.sortableColumn{
  cursor: pointer;
  border-left:1px solid #C1C5C8;
  &.selectedColumn{
      font-weight: 600;
      .sortColumnTitle{
          svg{
            g{
              fill:var(--color-app-henley-blue);
            }
          }
      }
  }

}

.selectedColumn{
  background: #f7f7f7;
}

.sortMobileWrapper{
  margin-bottom: 20px;
}

.sortTitle{
  font-size: 12px;
  font-family: "Roboto Regular";
  margin-bottom: 15px;
}

.cityColumn{
  .columnText{
    margin-bottom: -3px;
    display: inline-flex;
    vertical-align: bottom;
  }
  @media (max-width:799px){
      //width: 40%;
    width: auto;
  }
}

.hiddenTableRow{
  @media (min-width:800px){
      display: none;
  }

}



.overviewChartTable{
  //max-width: 990px;
  position: relative;

  .contentRow{
      @media (max-width: 799px){
          cursor: pointer;

        &.openedRow{
          td{
            &:first-child{
              border-bottom: 0;
            }
          }
        }
      }
  }
}

.linkText{
  color: inherit;
  text-decoration: underline;
  font-size: 16px;
}

.shareCellName{
  border: none;
}

.combinateRowCells {
  border-top: 1px solid #C1C5C8;
  border-left: 1px solid #C1C5C8;
  border-right: 0!important;
  vertical-align: middle;
  padding: 30px 10px 30px 0!important;

  >div{
    width: 100%;
    justify-content: flex-end;
  }
}


.countryMobileDiv {
  @media (max-width: 799px) {
    padding-top: 0!important;
  }
}

.showButton {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,255));
}

.collapseButton {
  color: #fff;
  background-color:  var(--color-app-henley-blue);
  border: 1px solid  var(--color-app-henley-blue);
    font-family: "Roboto Bold";
    font-size: 10px;
    letter-spacing: .163em;
  line-height: 1.4em;
  min-width: 190px;
  text-transform: uppercase;
    text-decoration: none;
    padding: 12px 30px;
    display: block;
    text-align: center;
    height: auto;
    border-radius: 2px;
  margin: 10px auto;
  

  @media screen and (max-width: 600px) {
    min-width: 100px;
  }
}

.bufferBeforeTotal {
  border-right: none!important;
  padding-top: 10px!important;
}

.progressBarColumn {
  width:150px;
  text-align: right;
  @media (min-width:800px) {
    //width: 28%;
    width: 200px;
    text-align: left;
  }

}

.topTableHeader{
  background: transparent!important;
  border-left: 1px solid #C1C5C8;
  border-right: 1px solid #C1C5C8;

  @media (max-width: 799px) {
    border-right: 0;
  }
}

.showTextOnMobile{
  @media (min-width: 800px) {
    display: none;
  }
}