.dropdownWrapper{
    position: relative;
}
.dropdownToggle{
    border: 1px solid var(--color-app-henley-blue);
    background-color: #fff;
    color: var(--color-app-henley-blue);
    font-size: 14px;
    padding: 17px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
}

.dropdownOptions{
    position: absolute;
    top: 100%;
    z-index: 99;
    background: #fff;
    border: 1px solid var(--color-app-henley-blue);
    border-top:none;
    width: 100%;
    padding: 9px 0;


    li{
        &:before{
            display: none;
   
        }
        margin-bottom: 0;
        padding: 9px 18px;
        cursor: pointer;
        font-size: 14px;
        &:hover{
            background-color: var(--color-app-henley-blue);
            color: #fff;
        }
    }
}

.menuItemNone{
    display: none;
}